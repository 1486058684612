import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DevGuestSessionParams } from '~src/api';
import { chat } from '~src/configurations';
import {
  ChatContext,
  CHATROOM_URL_PATH,
  CHAT_LOGIN_URL_PATH,
} from './chat-context';
import { ChatStatus, ChatUser } from './chat-types';
import { chatLogger } from './chat-utils';

const useEnterToChatRoom = (chatRoomId: string) => {
  const history = useHistory();
  const {
    userInfo: { status },
    chatRooms,
    sendEnterRoomRequestMessage,
    sendListRoomsRequestMessage,
    setUserInfo,
    clearChatContext,
    setChatError,
    chatTokens: { createGuestSession, createGuestSessionParams },
  } = useContext(ChatContext);

  useEffect(() => {
    if (status === ChatStatus.LoggedIn && chatRooms && !!chatRoomId) {
      if (chatRooms?.find((room) => room.roomId === chatRoomId)) {
        history.push(`${CHATROOM_URL_PATH}/${chatRoomId}`);
      } else {
        chatLogger(
          `useEnterToChatRoom - Did not find chat room with chatroomId:
            ${chatRoomId}, chatRooms: ${JSON.stringify(chatRooms)}, 
            status: ${status}`,
        );
        clearChatContext();
        setChatError(
          'Valittua chat -huonetta ei löytynyt tunnistautuneelle käyttäjälle',
        );
        history.push(`${CHAT_LOGIN_URL_PATH}/${chatRoomId}`);
      }
    } else {
      const message =
        status !== ChatStatus.LoggedIn
          ? 'User is not logged in'
          : !chatRooms
          ? 'Chat rooms not found'
          : 'Chat room not found';
      chatLogger(`useEnterToChatRoom - ${message}`);
    }
  }, [
    status,
    chatRooms,
    chatRoomId,
    history,
    sendEnterRoomRequestMessage,
    setUserInfo,
    clearChatContext,
    setChatError,
  ]);

  const jumpInToRoom = async (
    userSessionId: string,
    devParams?: DevGuestSessionParams,
  ) => {
    const devChatUserData = (
      chat.isDevelopment && devParams ? devParams : {}
    ) as Partial<ChatUser>;

    await createGuestSession({
      variables: { body: { userSessionId, devParams } },
    });
    setUserInfo((prev) => ({
      ...prev,
      ...devChatUserData, // set user data from devParams in development mode
      chatRoomId,
      status: ChatStatus.LoggedIn,
    }));
    sendListRoomsRequestMessage();
  };

  return { jumpInToRoom, createGuestSessionParams };
};

export { useEnterToChatRoom };
