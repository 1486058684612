import React from 'react';
import { Box, Flex, Image, VisuallyHidden } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import InfoToggle from '~/src/components/InfoToggle';
import LanguageSwitcher from '~/src/components/LanguageSwitcher';
import EndDiscussion from './EndDiscussion';
import { header, styles } from '~src/configurations';
import i18next, { t } from 'i18next';
import { Locale } from '~/src/types';
import { DoctorChatStatus } from './DoctorChatStatus';

const ChatHeader: React.FC = () => {
  const defaultLogoUrl =
    header.appLogoTranslated[i18next.language as Locale] || header.appLogo;
  return (
    <Flex
      as="header"
      borderBottom="1px solid"
      borderColor="gray.300"
      height="4.625rem"
      padding={4}
      position="sticky"
      top={0}
      justifyItems="space-between"
      bg={styles.pageBgColor}
      zIndex={1}
    >
      <Flex flex={1} flexDirection="row" gap={4}>
        <LanguageSwitcher />
        <DoctorChatStatus />
      </Flex>

      <Box
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        display={{ base: 'none', md: 'block' }}
      >
        <Link to="/">
          <VisuallyHidden>{t('common.app_name')}</VisuallyHidden>
          {defaultLogoUrl !== undefined && (
            <Image
              src={defaultLogoUrl}
              width={header.appLogoWidth}
              paddingTop={2}
              height="auto"
              alt={t('common.app_organization') ?? ''}
            />
          )}
        </Link>
      </Box>

      <Flex flexDirection="row" gap={2}>
        {header.showInfoToggle && (
          <InfoToggle display={['none', null, 'block']} />
        )}
        <EndDiscussion />
      </Flex>
    </Flex>
  );
};

export default ChatHeader;
