import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const SlashCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 25" {...props}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="slash-circle-01">
        <path
          id="Icon"
          d="M4.93 5.43L19.07 19.57M22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  </Icon>
);

export default SlashCircleIcon;
