import React, { useContext, useEffect } from 'react';
import { Authentication } from '~src/components/Authentication/Authentication';
import { CardContentStack } from '~src/components/Card';
import { chatAuthCallbackRoot } from '~src/constants';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Card,
  Center,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FlowResponse } from '~src/api';
import { ChatContext } from '~src/chat/chat-context';
import { chatLogger } from '~src/chat/chat-utils';

const ChatAuthentication: React.FC<{
  flowItem: FlowResponse['flowItem'];
  answerId: string;
}> = React.memo(({ flowItem, answerId }) => {
  const { t } = useTranslation();
  const {
    chatError,
    setChatError,
    userInfo: { chatRoomId },
  } = useContext(ChatContext);

  useEffect(() => {
    if (chatError) {
      chatLogger('Chat auth error:', chatError);
      setTimeout(() => setChatError(null), 10000);
    }
  }, [chatError, setChatError]);

  const { imgUrl, description } = flowItem || {};

  const callbackPath = `/${chatAuthCallbackRoot}/${chatRoomId}/${answerId}`;
  return (
    <Card>
      <CardContentStack mb={[4, null, 8]}>
        {chatError && (
          <Alert status="error" maxWidth={'640px'}>
            <AlertIcon />
            <AlertDescription mr={1}>{chatError}</AlertDescription>
          </Alert>
        )}
        {chatRoomId ? (
          <Authentication
            userSessionId={answerId}
            callbackPath={callbackPath}
            description={
              description || [
                `<center><strong>${t(
                  'chat.init.authenticate',
                )}</strong></center>`,
              ]
            }
            imgUrl={imgUrl}
            containerProps={{ padding: [0, 0, 0] }}
          />
        ) : (
          <Center>
            <Box>
              Osoitteestasi uupuu chat-huoneen tunniste. Ole hyvä ja yritä
              uudelleen.
            </Box>
          </Center>
        )}
      </CardContentStack>
    </Card>
  );
});

export { ChatAuthentication };
