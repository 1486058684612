import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Heading,
  AlertDialogBody,
  Text,
  Button,
  Flex,
} from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { ChatContext } from '~src/chat/chat-context';
import { ChatStatus } from '~src/chat/chat-types';
import { useEnterToChatRoom } from '~src/chat/useEnterToChatroom';
import { chat } from '~src/configurations';

const SessionExpiredModal = () => {
  const {
    clearChatContext,
    userInfo: { chatRoomId, userSessionIdTemp, hetu, givenNames, lastName },
  } = useContext(ChatContext);
  const cancelRef: React.RefObject<any> = useRef();
  const {
    jumpInToRoom,
    createGuestSessionParams: { reset, loading },
  } = useEnterToChatRoom(chatRoomId || '');

  const logout = useCallback(() => {
    reset?.();
    clearChatContext({ status: ChatStatus.LoggedOut });
  }, [clearChatContext, reset]);

  useEffect(
    () => void setTimeout(() => logout(), 7200000), // 2 hour
    [logout],
  );

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={true}
        leastDestructiveRef={cancelRef}
        onClose={() => {}}
      >
        <AlertDialogOverlay backdropFilter="blur(10px)" />

        <AlertDialogContent
          borderRadius="2rem"
          marginX={4}
          padding={8}
          textAlign="center"
          blur={true}
          maxWidth={['calc(100% - 2rem)', '28rem']}
        >
          <AlertDialogHeader
            as={Heading}
            fontWeight="bold"
            padding={0}
            size="sm"
          >
            Chat-keskustelu on aikakatkaistu turvallisuussyistä.
          </AlertDialogHeader>

          <AlertDialogBody maxWidth="23rem" paddingBottom={8} paddingTop={4}>
            <Text>
              <br></br>
              Voit jatkaa keskustelua valitsemalla &quot;Jatka&quot; tai
              kirjautua ulos. Pääset aina takaisin keskusteluun sähköpostisi
              linkin kautta.
            </Text>
            <Flex justifyContent="center" gap={4} mt={4} direction="column">
              <Button
                onClick={() =>
                  void jumpInToRoom(
                    userSessionIdTemp || '',
                    chat.isDevelopment
                      ? { hetu, givenNames, lastName }
                      : undefined,
                  )
                }
                isLoading={loading}
                colorScheme="primary"
                variant="solid"
                width="100%"
              >
                Jatka
              </Button>
              <Button
                onClick={() => logout()}
                colorScheme="primary"
                variant="outline"
                width="100%"
                disabled={loading}
              >
                Kirjaudu ulos
              </Button>
            </Flex>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export { SessionExpiredModal };
