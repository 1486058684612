import { Center, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AvatarPlaceholderIcon from '~/src/components/Chat/AvatarPlaceholderIcon';
import { ChatContext } from '~src/chat/chat-context';

const DiscussionHeader: React.FC<{ messagesSent?: boolean }> = () => {
  const { t } = useTranslation();
  const {
    userInfo: { givenNames, lastName },
  } = useContext(ChatContext);

  return (
    <Center flexDirection="column" paddingTop={12} gap={6} marginBottom={6}>
      <Heading as="h1" color="primary.500" textAlign="center">
        {t('chat.discussion.title')}
      </Heading>

      <AvatarPlaceholderIcon color="primary.500" width="4rem" height="auto" />

      <Heading as="h2" size="sm">
        {t('chat.discussion.waitingForService.title')}
      </Heading>

      <Flex bg="#EBEBEB" borderRadius="24px" p={4} w={['90%', '80%']}>
        <Text fontWeight={700} fontSize="sm">
          {givenNames} {lastName}
        </Text>
      </Flex>
    </Center>
  );
};

export default DiscussionHeader;
