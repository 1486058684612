import { Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ScrollToBottomButton = ({
  unreadMessageCount,
  scrollToBottom,
}: {
  unreadMessageCount: number;
  scrollToBottom: (behaviour?: ScrollBehavior) => void;
}) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (unreadMessageCount > 0) {
      // wait 500ms so unreadMessageCount has time to be updated.
      // otherwise when user already srcolled to bottom, button blinks to user.
      timer = setTimeout(() => {
        setShowScrollButton(true);
      }, 500);
    } else {
      setShowScrollButton(false);
    }
    return () => clearTimeout(timer);
  }, [unreadMessageCount]);

  if (!showScrollButton) return null;

  const text = t(
    unreadMessageCount === 1
      ? 'chat.discussion.newMessage'
      : 'chat.discussion.newMessages',
  );
  return (
    <Button
      onClick={() => {
        setShowScrollButton(false);
        scrollToBottom('smooth');
      }}
      position="sticky"
      bottom={3}
      zIndex={1}
      padding={1}
      boxShadow="md"
      width="fit-content"
      margin="auto"
    >
      {unreadMessageCount} {text}
    </Button>
  );
};

export { ScrollToBottomButton };
