import { useCallback, useEffect, useRef, useState } from 'react';
import { ListedMessage } from './chat-types';

const useChatScroll = (messages: ListedMessage[], isDoctorWriting: boolean) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [initialScrollDone, setInitialScrollDone] = useState<boolean>(false);

  const scrollToBottom = useCallback(
    (scrollBehavior: ScrollBehavior = 'smooth') => {
      messagesEndRef.current?.scrollIntoView({ behavior: scrollBehavior });
    },
    [],
  );

  const isNearBottom = useCallback(() => {
    if (!containerRef.current) return false;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    // 110px = bit more than one row message height + gap + margins/paddings
    return scrollHeight - scrollTop - clientHeight <= 110; // Check if near bottom
  }, []);
  /*
   * Scroll to bottom when:
   *  - chat opened and messages loaded (initialScrollDone is false)
   *  - new message is arrived and user is near the bottom (closer than 30px)
   */
  useEffect(() => {
    if ((isNearBottom() || !initialScrollDone) && messages.length > 1) {
      setTimeout(
        () => {
          scrollToBottom(initialScrollDone ? 'auto' : 'smooth');
        },
        100, // Delay to wait for message rendering
      );
      !initialScrollDone && setTimeout(() => setInitialScrollDone(true), 500);
    }
  }, [
    messages,
    isDoctorWriting,
    initialScrollDone,
    scrollToBottom,
    isNearBottom,
  ]);

  return { scrollToBottom, initialScrollDone, containerRef, messagesEndRef };
};

export default useChatScroll;
