import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Heading,
  AlertDialogBody,
  AlertDialogFooter,
  HStack,
  Button,
} from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useRef } from 'react';

const DoctorOfflineModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const cancelRef: React.RefObject<any> = useRef();
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
    >
      <AlertDialogOverlay />

      <AlertDialogContent
        borderRadius="2rem"
        marginX={4}
        padding={8}
        textAlign="center"
        maxWidth={['calc(100% - 2rem)', '28rem']}
      >
        <AlertDialogHeader as={Heading} fontWeight="bold" padding={0} size="sm">
          {t('chat.discussion.doctorOfflineModal.title')}
        </AlertDialogHeader>

        <AlertDialogBody as="p" paddingBottom={8} paddingTop={4}>
          {t('chat.discussion.doctorOfflineModal.paragraph')}
          <br />
          <br />
          {t('chat.discussion.doctorOfflineModal.paragraph1')}
        </AlertDialogBody>

        <AlertDialogFooter as={HStack} padding={0} justifyContent="center">
          <Button
            ref={cancelRef}
            onClick={onClose}
            minWidth={['8rem', '10rem']}
          >
            {t('chat.discussion.doctorOfflineModal.close')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { DoctorOfflineModal };
