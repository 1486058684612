import moment from 'moment';
import { isString } from 'lodash';

// Formats the time (hours and minutes) from a date string
export function formatTime(time: string) {
  const nowToday = new Date(time);
  const minutes =
    (nowToday.getMinutes() < 10 ? '0' : '') + nowToday.getMinutes();

  return `${nowToday.getHours()}:${minutes}`;
}

// Enum for DateTime Format
export enum DuodecimDateTimeFormat {
  ISO = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', // Moment.js format for ISO 8601
}

/**
 * Formats a given date into the specified format.
 * @param date - The date to format, which can be a Date object, number, or string.
 * @param duodecimFormat - The format to use, defaulting to ISO 8601.
 * @returns A string representing the formatted date.
 */
export const formatDate = (
  date: Date | number | string | moment.Moment,
  duodecimFormat = DuodecimDateTimeFormat.ISO,
): string => {
  const parsedDate = isString(date) ? new Date(date) : date;
  return moment(parsedDate).format(duodecimFormat);
};

export default formatTime;
