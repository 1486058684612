import React, { useContext, useEffect } from 'react';
import { Container, Box } from '@chakra-ui/react';
import MessageThread from '~/src/components/Chat/MessageThread';
import MessageInput from '~/src/components/Chat/MessageInput';
import { useHistory } from 'react-router-dom';
import { ChatContext, CHAT_LOGIN_URL_PATH } from '~src/chat/chat-context';
import { AppContext } from '~src/state';
import { ChatStatus } from '~src/chat/chat-types';
import { SessionExpiredModal } from './SessionExpiredModal';

const Discussion: React.FC = () => {
  const history = useHistory();
  const {
    userInfo: { chatRoomId, status, userSessionIdTemp },
    clearChatContext,
    chatTokens: { userSessionId },
  } = useContext(ChatContext);
  const { dispatch } = useContext(AppContext);
  const showSessionExpiredModal =
    status === ChatStatus.SessionExpired && userSessionIdTemp;

  useEffect(() => {
    if (!userSessionId && status !== ChatStatus.SessionExpired && chatRoomId) {
      clearChatContext();
      history.push(CHAT_LOGIN_URL_PATH + '/' + chatRoomId);
    }
  }, [userSessionId, history, chatRoomId, clearChatContext, dispatch, status]);

  return (
    <Box position="relative">
      {showSessionExpiredModal && <SessionExpiredModal />}
      <Container
        marginTop={[0, 0, 6]}
        marginX={[0, 0, 'auto']}
        maxWidth={['100%', '100%', '554px']}
        paddingX={0}
        display="flex"
        position="relative"
        flexDirection="column"
        bg="white"
        borderRadius={[0, 0, 8]}
        h={[
          'calc(100vh - 4.625rem)',
          'calc(100vh - 4.625rem)',
          'calc(100vh - 85px - 32px)',
        ]}
        justifyContent="space-between"
      >
        <MessageThread />
        <MessageInput />
      </Container>
    </Box>
  );
};

export default Discussion;
