import { AnswerInput, QuestionType } from '~/src/api';

export const langCookie = 'i18next';
export const mainContentId = 'maincontent';
export const surveyFlowRoot = 'survey';
export const reportRoot = 'report';
export const infoRoot = 'info';
export const privacyRoot = 'privacy';
export const accessibilityRoot = 'accessibility';
export const flowIndex = 'index';
export const flowAuthCallbackRoot = 'auth-callback';
export const chatAuthCallbackRoot = 'chat-auth-callback';
export const flowPaymentCallbackRoot = 'payment-callback';
export const closeSessionAnswerObject: AnswerInput = {
  name: 'closeSession',
  type: QuestionType.Special,
};
