import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ChatContext } from './chat-context';

/**
 * Hook to set chat room id from url params
 * - Have to be used inside ChatProvider
 * - Have to be inside Route with chatRoomId param
 */
const useSetChatRoomId = () => {
  const {
    userInfo: { chatRoomId },
    setUserInfo,
  } = useContext(ChatContext);
  const { chatRoomId: chatRoomIdParam } = useParams<{ chatRoomId: string }>();
  useEffect(() => {
    if (chatRoomIdParam && chatRoomIdParam !== chatRoomId)
      setUserInfo((prev) => ({ ...prev, chatRoomId: chatRoomIdParam }));
  }, [chatRoomIdParam, chatRoomId, setUserInfo]);

  return { chatRoomId: chatRoomIdParam };
};

export { useSetChatRoomId };
