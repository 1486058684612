// Generated with https://smart-swatch.netlify.app/ and all colors moved to
// one level larger to get 600 match brand color as in other palettes
const brand = {
  50: '#f1e7ff',
  100: '#f1e7ff',
  200: '#d1bafa',
  300: '#b18ef2',
  400: '#9260eb',
  500: '#431399', // <-- replaced with exact brand color
  600: '#431399',
  700: '#46149f',
  800: '#320d72',
  900: '#1e0746',
};

export default {
  brand,
  primary: brand,
  secondary: brand,
  red: {
    500: '#DE2362', // chat error toast bg color
  },
};
