const _temp0 = require("./alarming-symptom.svg");
const _temp1 = require("./authentication-success.svg");
const _temp2 = require("./authentication.svg");
const _temp3 = require("./duodecim-logo-blue.svg");
const _temp4 = require("./ear-infection.svg");
const _temp5 = require("./enigma.svg");
const _temp6 = require("./eye-infection.svg");
const _temp7 = require("./feedback.svg");
const _temp8 = require("./flow-completed.svg");
const _temp9 = require("./flu.svg");
const _temp10 = require("./generic-card-header.svg");
const _temp11 = require("./log-out.svg");
const _temp12 = require("./pain.svg");
const _temp13 = require("./skin.svg");
const _temp14 = require("./stomach-ache.svg");
const _temp15 = require("./symptom-inquiry.svg");
const _temp16 = require("./symptom-intro.svg");
const _temp17 = require("./thank-you.svg");
module.exports = {
  "alarming-symptom.svg": _temp0,
  "authentication-success.svg": _temp1,
  "authentication.svg": _temp2,
  "duodecim-logo-blue.svg": _temp3,
  "ear-infection.svg": _temp4,
  "enigma.svg": _temp5,
  "eye-infection.svg": _temp6,
  "feedback.svg": _temp7,
  "flow-completed.svg": _temp8,
  "flu.svg": _temp9,
  "generic-card-header.svg": _temp10,
  "log-out.svg": _temp11,
  "pain.svg": _temp12,
  "skin.svg": _temp13,
  "stomach-ache.svg": _temp14,
  "symptom-inquiry.svg": _temp15,
  "symptom-intro.svg": _temp16,
  "thank-you.svg": _temp17
}