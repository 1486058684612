import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import TypingDot from './TypingDot';

const TypingIndicator: React.FC = () => {
  return (
    <Box aria-hidden width="100%">
      <Flex
        bg="#EBEBEB"
        padding="10px; 8px"
        borderRadius="24px"
        height="39px"
        justifyContent="center"
        alignItems="center"
        w="fit-content"
      >
        <TypingDot />
        <TypingDot />
        <TypingDot />
      </Flex>
    </Box>
  );
};

export default TypingIndicator;
