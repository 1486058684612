import {
  FormControl,
  FormLabel,
  Input,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DevGuestSessionParams } from '~src/api';
import { ChatUser } from '~src/chat/chat-types';
import { chatLogger } from '~src/chat/chat-utils';
import { chat } from '~src/configurations';

type Dev_ChatUserOverrideModalProps = {
  onConfirm: (props?: DevGuestSessionParams) => void;
  loading: boolean;
  defaultUser: DevGuestSessionParams;
};

const Dev_ChatUserOverrideModal = ({
  loading,
  onConfirm,
  defaultUser,
}: Dev_ChatUserOverrideModalProps) => {
  const { t } = useTranslation();
  const cancelRef: React.RefObject<any> = useRef();
  const { hetu, givenNames, lastName } = defaultUser || {};
  const [chatInitPayload, setChatInitPayload] = useState<Partial<ChatUser>>({
    hetu: hetu || '',
    givenNames: givenNames || '',
    lastName: lastName || '',
  });

  useEffect(() => {
    setChatInitPayload({
      hetu: hetu || '',
      givenNames: givenNames || '',
      lastName: lastName || '',
    });
  }, [hetu, givenNames, lastName]);

  if (!chat.isDevelopment) return null;

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onConfirm}
        isOpen={true}
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          borderRadius="2rem"
          marginX={4}
          padding={8}
          textAlign="center"
          maxWidth={['calc(100% - 2rem)', '28rem']}
        >
          <AlertDialogHeader
            as={Heading}
            fontWeight="bold"
            padding={0}
            size="sm"
          >
            Pävivitä potilaan tiedot (DEV)
          </AlertDialogHeader>

          <AlertDialogBody
            onSubmit={(event) => {
              event.preventDefault();
              chatLogger('ChatInit', 'createGuestSession', chatInitPayload);
              void onConfirm(chatInitPayload);
            }}
            as="form"
            maxWidth="23rem"
            paddingBottom={8}
            paddingTop={4}
          >
            <Text>
              <strong>Tunnistautuminen onnistui.</strong>
              <br></br>
              Voit nyt halutessasi päivittää potilaan tiedot ennen
              chat-huoneeseen siirtymistä.
            </Text>
            <FormControl aria-label={t('chat.init.inputLabel') || ''} id="hetu">
              <FormLabel textAlign="center">Hetu</FormLabel>

              <Input
                type="text"
                value={chatInitPayload.hetu}
                onChange={(e) =>
                  setChatInitPayload((prev) => ({
                    ...prev,
                    hetu: e.target.value,
                  }))
                }
                placeholder={'112233-456A'}
                textAlign="center"
                autoComplete="off"
              />
              <FormLabel textAlign="center" pt={4}>
                Etunimi
              </FormLabel>
            </FormControl>

            <FormControl
              aria-label={t('chat.init.inputLabel') || ''}
              id="Etunimi"
            >
              <Input
                type="text"
                value={chatInitPayload.givenNames}
                onChange={(e) =>
                  setChatInitPayload((prev) => ({
                    ...prev,
                    givenNames: e.target.value,
                  }))
                }
                placeholder={'Etunimi'}
                textAlign="center"
                autoComplete="off"
              />

              <FormLabel textAlign="center" pt={4}>
                Sukunimi
              </FormLabel>
            </FormControl>

            <FormControl
              aria-label={t('chat.init.inputLabel') || ''}
              id="Sukunimi"
            >
              <Input
                type="text"
                value={chatInitPayload.lastName}
                onChange={(e) =>
                  setChatInitPayload((prev) => ({
                    ...prev,
                    lastName: e.target.value,
                  }))
                }
                placeholder={'Sukunimi'}
                textAlign="center"
                autoComplete="off"
              />
            </FormControl>
            <Button
              ref={cancelRef}
              type="submit"
              isDisabled={
                !chatInitPayload.givenNames ||
                !chatInitPayload.hetu ||
                !chatInitPayload.lastName
              }
              isLoading={loading}
              mt={8}
              minWidth={['8rem', '10rem']}
            >
              Siirry Chat -huoneeseen
            </Button>
          </AlertDialogBody>

          <AlertDialogFooter
            as={HStack}
            padding={0}
            justifyContent="center"
          ></AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export { Dev_ChatUserOverrideModal };
