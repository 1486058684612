import { Text, Flex, Image, useTheme } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useMemo,
} from 'react';
import { useDropzone } from 'react-dropzone';
import {
  ImageTypes,
  ImageUploadProps,
  UploadedImage,
  useUploadImagesMutation,
} from '~src/api';
import { devlogger } from '~src/utils';
import UploadIcon from './UploadIcon';
import SlashCircleIcon from './SlashCircleIcon';
import KameraIcon from './KameraIcon';
import XIcon from '../../images/XIcon.svg';
import { useTranslation } from 'react-i18next';

const defaultMaxImages = 10;
const defaultTotalMaxSize = 15;
const defaultAllowedImgTypes: ImageTypes[] = [
  ImageTypes.Jpg,
  ImageTypes.Jpeg,
  ImageTypes.Png,
  ImageTypes.Gif,
  ImageTypes.Bmp,
  ImageTypes.Tiff,
  ImageTypes.Webp,
  ImageTypes.Svg,
];

const baseStyle = {
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  borderWidth: 2,
  borderRadius: 8,
  maxWidth: '100%',
  maxHeight: '100%',
  textAlign: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  outline: 'none',
  transition: 'border 0.24s ease-in-out',
  borderStyle: 'dashed',
  backgroundColor: '#EBEBEB',
  color: '#000000',
  borderColor: '#8298A5',
  overflow: 'hidden',
  flexShrink: 1,
  boxSizing: 'border-box',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: 'primary',
  backgroundColor: '#DBE1FB',
  color: 'primary.500',
};

const rejectStyle = {
  borderColor: '#FA3030',
  color: '#FA3030',
};

type FileWithPreview = File & { preview?: string; id?: string };

const ImageUpload = forwardRef((props: Maybe<ImageUploadProps>, ref) => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const { maxImages, totalMaxSize, allowedTypes } = props || {};
  const { t } = useTranslation();
  const theme = useTheme();
  const primaryColor = theme.colors.primary[500];
  const acceptedFileTypes = (allowedTypes ?? defaultAllowedImgTypes).reduce(
    (acc, type) => ({
      ...acc,
      [`image/${type}`]: [`.${type}`],
    }),
    {},
  );

  const [uploadImages] = useUploadImagesMutation({
    onError: (error) => {
      devlogger('Upload error:', error);
    },
  });

  const sendFiles = async () => {
    try {
      const res = await uploadImages({
        variables: { files: files },
      });
      return res.data?.uploadImages.map((image: UploadedImage) => ({
        id: image.id,
        filename: image.filename,
      }));
    } catch (error) {
      devlogger('Upload error:', error);
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    sendFiles,
  }));

  const onDrop = useCallback((acceptedFilesOnDrop: FileWithPreview[]) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFilesOnDrop.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: parseInt(Math.random() * file.size + ''),
        }),
      ),
    ]);
  }, []);
  function totalSizeValidator(file: File) {
    const currentFilesSize = files.reduce((acc, file) => acc + file.size, 0);
    const maxSize = (totalMaxSize || defaultTotalMaxSize) * 1024 * 1024;
    if (currentFilesSize + file.size > maxSize) {
      return {
        code: 'total-size-too-large',
        message: `Total size of files exceeds ${maxSize} MB`,
      };
    }

    return null;
  }
  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    validator: totalSizeValidator,
    maxFiles: maxImages ?? defaultMaxImages,
    accept: acceptedFileTypes,
    maxSize: (totalMaxSize ?? defaultTotalMaxSize) * 1024 * 1024, // mb
  });

  const removeImage = (id: string) =>
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));

  const thumbs = files.map((file) => (
    <Flex
      display="flex"
      flexDir="column"
      bgColor="#F2F3F6"
      borderRadius="2px"
      border="1px solid #eaeaea"
      marginBottom={2}
      marginRight={2}
      maxWidth="100px"
      maxHeight="100px"
      padding={1}
      boxSizing="border-box"
      key={file.id ?? file.name}
      position="relative"
    >
      <Flex
        position="absolute"
        top={'-10.5px'}
        right={'-10px'}
        rounded="full"
        bg="primary.500"
        onClick={() => removeImage(file.id ?? '')}
        cursor="pointer"
        alignItems="center"
        padding="7px"
      >
        <Image src={XIcon} color="white" width="12px" height="12px" />
      </Flex>
      <Flex minW={0} overflow="hidden">
        <Image
          src={file.preview}
          display="block"
          width="auto"
          height="100%"
          // Revoke data uri after image is loaded
          onLoad={() => {
            if (file.preview) {
              URL.revokeObjectURL(file.preview);
            }
          }}
        />
      </Flex>
      <Text fontSize="xs" fontWeight="bold" mt={1}>
        {file.size > 1024 * 1024
          ? `${(file.size / (1024 * 1024)).toFixed(2).replace('.', ',')} Mt`
          : `${(file.size / 1024).toFixed(2).replace('.', ',')} kt`}
      </Text>
    </Flex>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file?.preview || '');
      });
    };
  }, [files]);

  const style = useMemo(
    () =>
      ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept
          ? { ...acceptStyle, color: primaryColor, borderColor: primaryColor }
          : {}),
        ...(isDragReject ? rejectStyle : {}),
      } as React.CSSProperties),
    [isFocused, isDragAccept, isDragReject, primaryColor],
  );

  return (
    <Flex flexDir="column" gap="32px" mb="24px">
      <Flex
        {...getRootProps({ style })}
        width={['100%', '400px', '460px']}
        height={['100px', '195px', '195px']}
      >
        <input
          {...getInputProps()}
          capture="environment" // Triggers rear camera on supported devices
        />
        {isDragAccept && (
          <UploadIcon color="primary.500" width="24px" height="24px" />
        )}
        {isDragReject && (
          <>
            <SlashCircleIcon color="#FA3030" width="24px" height="24px" />
            <p>{t('errors.imageUpload.notValidFile')}</p>
          </>
        )}
        {!isDragReject && <p>{t('imageUpload.selectOrDropFile')}</p>}
      </Flex>

      <Flex
        display={['flex', 'none']}
        onClick={(e) => {
          e.stopPropagation();
          const input = document.querySelector(
            'input[type="file"]',
          ) as HTMLInputElement;
          if (input) input.click(); // Open file input manually
        }}
        cursor="pointer"
        justifyContent="center"
        alignItems="center"
        color="primary.500"
        flexDirection="column"
        gap="4px"
      >
        <KameraIcon color="primary.500" width="24px" height="24px" />
        <Text fontSize="md">{t('imageUpload.openCamera')}</Text>
      </Flex>
      {(fileRejections.length > 0 || isDragReject) && (
        <Flex gap="4px" flexDir="column" color="#FA3030">
          <p>{t('errors.imageUpload.notValidFile')}</p>
          <Text fontSize="xs">
            - {t('errors.imageUpload.maxFiles', { maxFiles: maxImages })}
          </Text>
          <Text fontSize="xs">
            -{' '}
            {t('errors.imageUpload.allowedFileTypes', {
              allowedFileTypes: (allowedTypes ?? defaultAllowedImgTypes).join(
                ', ',
              ),
            })}
          </Text>
          <Text fontSize="xs">
            -{' '}
            {t('errors.imageUpload.maxFilesSize', {
              maxFilesSize: totalMaxSize ?? 15,
            })}
          </Text>
        </Flex>
      )}
      {thumbs.length > 0 && (
        <Flex flexDirection="row" wrap="wrap" gap="24px">
          {thumbs}
        </Flex>
      )}
    </Flex>
  );
});

export { ImageUpload };
