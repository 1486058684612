import React, { useEffect, useReducer } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useTranslation } from 'react-i18next';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import './i18n';
import { AppContext, initialState, reducer } from './state';
import { envs, setError } from '~/src/utils';
import { createStandaloneToast } from '@chakra-ui/react';
import theme from '~/src/theme/theme';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const errors = { graphQLErrors, networkError };
  let showToastMessageForError = true;

  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable no-console */
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
    /* eslint-enable no-console */
  }

  graphQLErrors &&
    graphQLErrors.map(({ message }) => {
      if (
        (message && message.indexOf('FlowResponse.id') > 0) ||
        message?.indexOf('"$id" of required type "ID!" was not provided') > 0
      ) {
        showToastMessageForError = false;
      }
    });

  showToastMessageForError && setError(errors);
});

const httpLink = new HttpLink({
  uri: envs.GRAPHQL_API_URL,
});
const uploadLink = createUploadLink({
  uri: envs.GRAPHQL_API_URL,
  headers: {
    'apollo-require-preflight': 'true', // This triggers a preflight request to avoid CSRF issues
  },
});

const client = new ApolloClient({
  uri: envs.GRAPHQL_API_URL,
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, uploadLink, httpLink]),
});

export const Root: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  const { t, i18n } = useTranslation();
  const { ToastContainer } = createStandaloneToast({ theme: theme });

  const title = i18n.exists('common.pageTitle')
    ? t('common.pageTitle')
    : process.env.SITE_TITLE;

  useEffect(() => {
    document.title = title ?? 'Kuura Health';
  }, [title]);

  return (
    <>
      <AppContext.Provider value={value}>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </AppContext.Provider>
      <ToastContainer />
    </>
  );
};
