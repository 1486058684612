export type ChatUser = {
  givenNames: string;
  lastName: string;
  hetu: string;
  userGuid: string;
  status: ChatStatus;
  chatRoomId?: string;
  userSessionIdTemp?: string | null;
};

export enum ChatStatus {
  LoggedIn = 'loggedIn',
  LoggedOut = 'loggedOut',
  SessionExpired = 'sessionExpired',
}

export type ChatSettings = object; //{};

export enum WebsocketMessageType {
  Unknown = 'Unknown',
  AuthorizationMessage = 'AuthorizationMessage',
  ListRoomsRequestMessage = 'ListRoomsRequestMessage',
  RoomListMessage = 'RoomListMessage',
  NewChatMessage = 'NewChatMessage',
  EnterRoomRequestMessage = 'EnterRoomRequestMessage',
  ExitRoomRequestMessage = 'ExitRoomRequestMessage',
  UpdateRoomStatusMessage = 'UpdateRoomStatusMessage',
  GetRoomMessagesRequestMessage = 'GetRoomMessagesRequestMessage',
  RoomMessagesListMessage = 'RoomMessagesListMessage',
  UpdateMessageStatusMessage = 'UpdateMessageStatusMessage',
  ClientStatusUpdateMessage = 'ClientStatusUpdateMessage',
  RoomMemberListChangeMessage = 'RoomMemberListChangeMessage',
  PingMessage = 'PingMessage',
  ErrorMessage = 'ErrorMessage',
}

export enum ChatRoomStatus {
  Unknown = 'Unknown',
  Open = 'Open',
  Closed = 'Closed',
}

export enum ChatterRole {
  Unknown = 'Unknown',
  Doctor = 'Doctor',
  Patient = 'Patient',
}

export enum ChatterStatus {
  Unknown = 'Unknown',
  Offline = 'Offline',
  Online = 'Online',
  Writing = 'Writing',
}

export enum ChatMessageContentType {
  Unknown = 'Unknown',
  Text = 'Text',
}

export interface AuthorizationMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  authorizationToken?: string | null;
}

export interface ListRoomsFilter {
  /**
   * Two things count as activity: Room creation and new non-system messages.
   * @format int32
   */
  activeWithinHours: number;
  /** If omitted, return rooms of any status */
  status?: ChatRoomStatus | null;
}

/**
 * Client sent request to enter a chat room.
 * Responded to by ClientStatusUpdateMessage if successful
 * MessageType: EnterRoomRequestMessage
 */
export interface EnterRoomRequestMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  roomId: string;
}

/**
 * Sent by client to request messages of room the client has joined
 * Responded to by RoomMessageListMessage if successful
 * MessageType: GetRoomMessagesRequestMessage
 */
export interface GetRoomMessagesRequest {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  roomId: string;
  /** @format date-time */
  since?: string | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  take?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  skip?: number | null;
}

/**
 * Client sent request to exit a chat room.
 * Responded to by ClientStatusUpdateMessage if successful
 * MessageType: ExitRoomRequestMessage
 */
export interface ExitRoomRequestMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /** @format uuid */
  roomId: string;
}

/**
 * Sent by client when changing their status. Sent by server when status of room participant changes.
 * MessageType: ClientStatusUpdateMessage
 */
export interface ClientStatusUpdateMessage {
  messageType: WebsocketMessageType;
  /**
   * Time when this websocket message was sent
   * @format date-time
   */
  timeStamp: string;
  /**
   * Irrelevant in messages sent by client.
   * @minLength 1
   */
  firstAndLastName: string;
  /**
   * User identifier, either User id or Patient id. Irrelevant in messages sent by client.
   * @format uuid
   */
  guid?: string | null;
  /**
   * Id of room the change pertains to. Null if global change, such as in the initial update after logging in.
   * @format uuid
   */
  roomId?: string | null;
  /** Client can only change status between Online and Writing with this message. */
  status: ChatterStatus;
}

export interface RoomMember {
  /** @minLength 1 */
  firstAndLastName: string;
  role: ChatterRole;
  status: ChatterStatus;
  /**
   * Identifier that is unique to the chatter's person.
   * @format uuid
   */
  guid: string;
  /** This is only non-null in messages sent to doctors, and even then only non-null for RoomMembers where Role is Patient */
  ssid?: string | null;
}

export interface ListedMessage {
  /** @format uuid */
  messageId: string;
  contentType: ChatMessageContentType;
  /** @minLength 1 */
  content: string;
  /**
   * Time when message was saved to database.
   * @format date-time
   */
  createdTimestamp?: string | null;
  /** Null if message is system message. */
  senderName?: string | null;
  /**
   * Null if message is system message.
   * @format uuid
   */
  senderGuid?: string | null;
  status: ChatMessageDeliveryStatus;
}

export enum ChatMessageDeliveryStatus {
  Unknown = 'Unknown',
  Delivering = 'Delivering',
  Delivered = 'Delivered',
  Read = 'Read',
}

export interface RoomInfo {
  /** @format uuid */
  roomId: string;
  /** @format int32 */
  unreadMessageCount: number;
  /** @format date-time */
  created: string;
  /**
   * Defined as creation time of last non-system message in room. Null for rooms with no messages yet.
   * @format date-time
   */
  lastActive?: string | null;
  /**
   * Id of queue where patient of room was taken from. Null if patient was not from a queue.
   * @format uuid
   */
  queueId?: string | null;
  /**
   * Identifier that is unique to the patient queue item this room is connected to, same as 'Guid' in GET /Patients end point results.
   * @format uuid
   */
  patientQueueItemGuid: string;
  members: RoomMember[];
  status: ChatRoomStatus;
}

export enum ChatErrorCode {
  Unkown = 'Unkown',
  UnknownUser = 'UnknownUser',
  InvalidSession = 'InvalidSession',
  ActionForbidden = 'ActionForbidden',
  RoomNotFound = 'RoomNotFound',
  InvalidMessage = 'InvalidMessage',
  InvalidRoom = 'InvalidRoom',
  MessageNotFound = 'MessageNotFound',
  InvalidRequest = 'InvalidRequest',
}
