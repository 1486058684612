import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChatSendIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_17726_22743)">
        <path
          d="M10.4976 13.5002L20.9976 3.00017M10.6251 13.8282L13.2533 20.5862C13.4848 21.1816 13.6005 21.4793 13.7673 21.5662C13.9119 21.6415 14.0842 21.6416 14.2289 21.5664C14.3958 21.4797 14.5119 21.1822 14.7441 20.5871L21.3345 3.69937C21.5441 3.16219 21.6489 2.8936 21.5916 2.72197C21.5418 2.57292 21.4248 2.45596 21.2758 2.40616C21.1041 2.34883 20.8356 2.45364 20.2984 2.66327L3.41062 9.25361C2.81555 9.48584 2.51801 9.60195 2.4313 9.76886C2.35614 9.91354 2.35624 10.0858 2.43158 10.2304C2.51848 10.3972 2.81615 10.513 3.4115 10.7445L10.1695 13.3726C10.2904 13.4196 10.3508 13.4431 10.4017 13.4794C10.4468 13.5115 10.4862 13.551 10.5184 13.5961C10.5547 13.647 10.5782 13.7074 10.6251 13.8282Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17726_22743">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
