import * as React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LogOutIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g fill="none">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 17l5-5m0 0l-5-5m5 5H9m0-9H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C5.28 21 6.12 21 7.8 21H9"
        />
      </g>
    </Icon>
  );
};
