import React from 'react';
import { useTranslation } from 'react-i18next';
import AuthenticationImage from '~/src/images/image-bank/authentication.svg';
import { CardContentStack } from '~/src/components/Card';
import { flowAuthCallbackRoot } from '~/src/constants';
import { FlowResponse } from '~/src/api';
import { Authentication } from '../Authentication/Authentication';

const SurveyAuthentication: React.FC<{
  flowItem: FlowResponse['flowItem'];
  answerId: string;
}> = React.memo(({ flowItem, answerId }) => {
  const { t } = useTranslation();
  const { title, description, imgUrl } = flowItem || {};

  const callbackPath = `/${flowAuthCallbackRoot}/survey/${answerId}`;

  return (
    <CardContentStack mb={[4, null, 8]}>
      <Authentication
        userSessionId={answerId}
        callbackPath={callbackPath}
        title={title || t('healthIssueFlow.authentication.title')}
        description={
          description || [t('healthIssueFlow.authentication.paragraph')]
        }
        imgUrl={imgUrl || AuthenticationImage}
      />
    </CardContentStack>
  );
});

export { SurveyAuthentication };
